import React, { useState, useEffect, useRef } from 'react';
import { redirect, useParams, useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageUploading from 'react-images-uploading';
import MarkdownEditor from '@gravitational-marketing/ckeditor5-build-markdown';
import contexts from '../../context/contexts';
import Loading from '../../widgets/Loading';
import './Event.less';
import './EditEvent.less';

const {
	useStore,
	useDispatch,
	makeDispatcher
} = contexts.events;

const debug = false;

const TimeEdit = ({ label, value, onChange }) => {
	return (
		<Col>
			<Form.Label>{label}</Form.Label>
			 <Form.Control
			 	placeholder={`The event ${label.toLowerCase()}`}
			 	value={value}
			 	onChange={onChange}
			 />
		</Col>
	);
}

export default function EditEvent() {
	const dispatch = makeDispatcher(useDispatch());
	const { events } = useStore();
	const params = useParams();
	const [images, setImages] = useState([]);
	const [event, setEvent] = useState(null);
	const [descriptionEditor, setDescriptionEditor] = useState(null);
	const [summaryEditor, setSummaryEditor] = useState(null);
	const [initialized, setInitialized] = useState(false);
	const [saved, setSaved] = useState(false);
	const navigate = useNavigate();
	const credentials = sessionStorage.getItem('credentials');
	const authenticated = !!credentials;
	const descriptionEditorRef = useRef(null);
	const summaryEditorRef = useRef(null);

	const log = debug ? console.log : () => {};

	const onUpdateImage = (imageList, addUpdateIndex) => {
		if (imageList.length) {
			handleUpdate(null, 'image', imageList[0].data_url);
		} else {
			handleUpdate(null, 'image', undefined);
		}
		setImages(imageList);
	};

	const onSave = () => {
		const credentials = sessionStorage.getItem('credentials');
		dispatch({
			type: 'UPDATE_EVENT',
			onSuccess: () => {
				setSaved(true);
			},
			payload: {
				credentials,
				event
			}
		});
	}

	if (!authenticated) {
		log('not authenticated, navigating to /calendar');
		return navigate('/calendar');
	}

	log('edit-event', events);

	// equivalent of componentDidMount
	useEffect(() => {
		log('use-effect-1');
		if (!events.data.length) {
			dispatch({ type: 'FETCH_EVENTS' });
			return;
		}
	}, []); // the [] is for when mounting only

	useEffect(() => {
		log('use-effect-2');
		if (!(events && events.finishedLoading && events.data.length)) {
			log('no events', events);
			return;
		}
		const foundEvent = events.data.find(ev => ev.date === params.date);
		if (foundEvent) {
			setEvent(JSON.parse(JSON.stringify(foundEvent)));
		}
	}, [events && events.finishedLoading]);

	// effect when the event changes
	useEffect(() => {
		log('use-effect-3', event);
		if (!event) {
			return;
		}
		if (event.image) {
			setImages([{
				data_url: event.image
			}]);
		}
		if (!initialized) {
			setInitialized(true);
		}
	}, [event]);

	useEffect(() => {
		log('use-effect-4', event);
		if (!event) {
			return;
		}

		MarkdownEditor
			.create(document.querySelector('#summary'), {
				initialData: event.summary,
				removePlugins: [ 'Heading' ],
				toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'link' ]
			})
			.then((editor) => {
				summaryEditorRef.current = editor;
				document.querySelector('.summary-container .ck-content').addEventListener('blur', () => {
					const summary = editor.getData();
					if (event.summary !== summary) {
						handleUpdate(null, 'summary', editor.getData());
					}
				});

			})
			.catch(ex => console.error);

		return (() => {
			if (summaryEditorRef.current) {
				summaryEditorRef.current.destroy()
					.then(() => summaryEditorRef.current = null)
					.catch(console.error)
			} else {
				console.warn('something happened to summaryEditor');
			}
		});
	}, [event, summaryEditorRef]);

	useEffect(() => {
		log('use-effect-5', event);
		if (!event) {
			return;
		}

		MarkdownEditor
			.create(document.querySelector('#description'), {
				initialData: event.description,
				removePlugins: [ 'Heading' ],
				toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'link' ]
			})
			.then((editor) => {
				descriptionEditorRef.current = editor;
				document.querySelector('.description-container .ck-content').addEventListener('blur', () => {
					const description = editor.getData();
					if (event.description !== description) {
						handleUpdate(null, 'description', editor.getData());
					}
				});
			})
			.catch(ex => console.error);

		return (() => {
			if (descriptionEditorRef.current) {
				descriptionEditorRef.current.destroy()
					.then(() => descriptionEditorRef.current = null)
					.catch(console.error)
			} else if (event) {
				console.warn('something happened to descriptionEditor', descriptionEditor);
			}
		});
	}, [event, descriptionEditorRef]);

	useEffect(() => {
		if (saved) {
			log('navigating to /calendar');
			return navigate('/calendar');
		}
	}, [saved]);

	function handleUpdate(ev, prop, value) {
		ev && ev.preventDefault();
		setEvent({
			...event,
			[prop]: value
		});
	}

	if (!events.finishedLoading) {
		return <Loading title="Loading events..." />;
	}
	if (!event) {
		log('something went wrong and failed to get event', params);
		return null;
	}

	const {
		date,
		dateDay,
		dateMonthAbbrev,
		fromTime,
		toTime,
		type,
		title,
		summary,
		description,
		location,
		image,
		tags
	} = event;

	return (
		<div key="edit-event" className="box-content box">
			<div className="edit-event h-event" id="edit-event">
				<div className="event-image">
					<ImageUploading
						value={images}
						onChange={onUpdateImage}
						maxNumber={1}
						dataURLKey="data_url"
					>
						{({
							imageList,
							onImageUpload,
							onImageUpdate,
							onImageRemove,
							isDragging,
							dragProps,
						}) => (
							<div className="upload-image">
								{!imageList.length && (
									<div onClick={onImageUpload} {...dragProps}>
										Click or Drop image here
									</div>
								)}
								{imageList.map((image, index) => (
									<div key={index} className="image-item">
										<img src={image['data_url']} alt="event image" />
										<span
											onClick={() => onImageRemove(index)}
											className="remove-image bi-x-circle-fill">
										</span>
									</div>
								))}
							</div>
						)}
					</ImageUploading>
				</div>
				<div className="event-title">
					<div className="event-date">
						<span className="event-day">{dateDay}</span>
						<span className="event-month">{dateMonthAbbrev}</span>
					</div>
					<Form.Control
						placeholder={`The title`}
						value={title}
						onChange={(ev) => {
							handleUpdate(ev, 'title', ev.target.value);
						}}
					/>
				</div>
				<h3>Summary</h3>
				<div className="summary-container">
					<div id="summary">
					</div>
				</div>
				<h3>Detailed description</h3>
				<div className="description-container">
					<div id="description">
						{description}
					</div>
				</div>
				<h3>Event time</h3>
				<div className="time-container">
					<Row>
						<TimeEdit
							key="event-from-time"
							label="Start time"
							value={fromTime}
							onChange={(ev) => {
								handleUpdate(ev, 'fromTime', ev.target.value);
							}}
						/>
						<TimeEdit
							key="event-to-time"
							label="End time"
							value={toTime}
							onChange={(ev) => {
								handleUpdate(ev, 'toTime', ev.target.value);
							}}
						/>
					</Row>
				</div>
				<div className="location-container">
					<h3>Location</h3>
					<Form>
						<Form.Check
							checked={location === 'teachers-club'}
							type="radio"
							name="location"
							id="location-teachers-club"
							label="Teachers' Club"
							onChange={() => {
								handleUpdate(null, 'location', 'teachers-club');
							}}
						/>
						<Form.Check
							checked={location === 'zoom'}
							type="radio"
							name="location"
							id="location-zoom"
							label="Zoom"
							onChange={() => {
								handleUpdate(null, 'location', 'zoom');
							}}
						/>
					</Form>
				</div>
				<div className="tags-container">
					<h3>Tags</h3>
					<Form>
						<Form.Check
							checked={tags.includes('open-singing-session')}
							type="checkbox"
							name="tags"
							id="tag-open-singing-session"
							label={<Badge bg="success">open-singing-session</Badge>}
							onChange={(ev, value) => {
								const index = tags.indexOf('open-singing-session');
								if (index >= 0) {
									tags.splice(index, 1);
								} else {
									tags.push('open-singing-session');
								}
								handleUpdate(null, 'tags', tags);
							}}
						/>
						<Form.Check
							checked={tags.includes('special-guest')}
							type="checkbox"
							name="tags"
							id="tag-special-guest"
							label={<Badge bg="danger">special-guest</Badge>}
							onChange={(ev, value) => {
								const index = tags.indexOf('special-guest');
								if (index >= 0) {
									tags.splice(index, 1);
								} else {
									tags.push('special-guest');
								}
								handleUpdate(null, 'tags', tags);
							}}
						/>
						<Form.Check
							checked={tags.includes('as-gaeilge')}
							type="checkbox"
							name="tags"
							id="tag-as-gaeilge"
							label={<Badge bg="primary">as-gaeilge</Badge>}
							onChange={(ev, value) => {
								const index = tags.indexOf('as-gaeilge');
								if (index >= 0) {
									tags.splice(index, 1);
								} else {
									tags.push('as-gaeilge');
								}
								handleUpdate(null, 'tags', tags);
							}}
						/>
					</Form>
				</div>
				<div>
					<Button
						className="cancel"
						variant="secondary"
						onClick={() => navigate(-1)}
					>
						Cancel
					</Button>
					<Button
						className="save"
						variant="primary"
						onClick={onSave}
					>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
}
