import React from 'react';
import { useHistory } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import ReactMarkdown from 'react-markdown';
import EventDate from './EventDate';
import EventTime from './EventTime';
import Tags from './Tags';
import './Event.less';

export default function EditableEvent({ event, onEdit, onConvert, onDelete }) {
	const {
		date,
		dateDay,
		dateMonthAbbrev,
		fromTime,
		toTime,
		type,
		title,
		image: img,
		summary,
		description,
		location,
		url,
		tags,
		flags
	} = event;

	return (
		<div className="editable-event h-event">
			<div className="event-title">
				<EventDate
					type={type}
					day={dateDay}
					month={dateMonthAbbrev}
				/>
				<h1 className="p-name">{title}</h1>
				<EventTime
					date={date}
					fromTime={fromTime}
					toTime={toTime}
				/>
				<div className="three-dots-menu">
					<Dropdown as={ButtonGroup}>
						<Button href={`/calendar/${date}/edit`} variant="light" onClick={(ev) => {
							ev.stopPropagation();
							ev.preventDefault();
							onEdit(event);
						}}>
							<span className="bi-pencil"></span>
							Edit
						</Button>
						<Dropdown.Toggle split variant="light" id="dropdown-split-basic" />
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => onConvert(event, 'zoom')}>
								Convert to <b>open-singing-session</b>
							</Dropdown.Item>
							<Dropdown.Item onClick={() => onConvert(event, 'as-gailge')}>
								Convert to <b>as-gailge</b>
							</Dropdown.Item>
							<Dropdown.Item onClick={() => onConvert(event, 'special-guest')}>
								Convert to <b>special-guest</b>
							</Dropdown.Item>
							<Dropdown.Item onClick={() => onConvert(event, 'none')}>
								Convert to <b>closed</b> (no session)
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item onClick={() => onDelete(event)}>
								<span className="bi-trash-fill"></span>
								Delete
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
			{img && img && (
				<div className="image-container">
					<img src={img} alt={title} />
				</div>
			)}

			<h3>Summary</h3>
			<ReactMarkdown className="p-summary text-0">
				{summary ? summary : '_None_'}
			</ReactMarkdown>

			<h3>Detailed description</h3>
			<ReactMarkdown className="p-summary text-0">
				{description ? description : '_None_'}
			</ReactMarkdown>

			<div className="event-details">
				<span className="p-location">
					<Tags location={location} tags={tags} />
				</span>
				{ fromTime && toTime && <span className="start-at text-2">
					{fromTime} - {toTime} (GMT)
				</span> }
				<a href={url} className="p-url">permalink</a>
			</div>

			<div className="event-actions">
			</div>
		</div>
	);
}
